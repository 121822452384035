import "react-app-polyfill/ie11"; // For IE 11 support
import "react-app-polyfill/stable";
import React, { StrictMode, Suspense } from "react";
import { createRoot } from "react-dom/client";
import packageJson from "../package.json";
import { RecoilRoot } from "recoil";
import { GlobalStyle } from "./components/styles";
import { BrowserRouter, Route, Routes } from "react-router-dom";
// 이외에 필요한 스타일 파일이 있다면 import 해주세요.

console.log(
  `%cNE(O)RDINARY Project Name : ${packageJson.name}`,
  "color:yellow"
);
console.log(
  `%cNE(O)RDINARY Project Version : ${packageJson.version}`,
  "color:yellow"
);
console.log(
  `%cNE(O)RDINARY Project Made by : ${packageJson.author}`,
  "color:yellow"
);

console.log(`
███╗   ██╗███████╗ ██╗ ██████╗ ██╗ ██████╗ ██████╗ ██╗███╗   ██╗ █████╗ ██████╗ ██╗   ██╗
████╗  ██║██╔════╝██╔╝██╔═══██╗╚██╗██╔══██╗██╔══██╗██║████╗  ██║██╔══██╗██╔══██╗╚██╗ ██╔╝
██╔██╗ ██║█████╗  ██║ ██║   ██║ ██║██████╔╝██║  ██║██║██╔██╗ ██║███████║██████╔╝ ╚████╔╝
██║╚██╗██║██╔══╝  ██║ ██║   ██║ ██║██╔══██╗██║  ██║██║██║╚██╗██║██╔══██║██╔══██╗  ╚██╔╝
██║ ╚████║███████╗╚██╗╚██████╔╝██╔╝██║  ██║██████╔╝██║██║ ╚████║██║  ██║██║  ██║   ██║
╚═╝  ╚═══╝╚══════╝ ╚═╝ ╚═════╝ ╚═╝ ╚═╝  ╚═╝╚═════╝ ╚═╝╚═╝  ╚═══╝╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝
`);

const loading = <div>화면을 불러오는 중 입니다.</div>;

// Containers
const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));

// Pages
const Page404 = React.lazy(() => import("./pages/page404/Page404"));
const Page500 = React.lazy(() => import("./pages/page500/Page500"));

const container = document.getElementById("root");
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

root.render(
  <StrictMode>
    <RecoilRoot>
      <GlobalStyle />
      <BrowserRouter>
        <Suspense fallback={loading}>
          <Routes>
            <Route path="/404" element={<Page404 />} />
            <Route path="/500" element={<Page500 />} />
            <Route path="/*" element={<DefaultLayout />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </RecoilRoot>
  </StrictMode>
);
