import { createGlobalStyle } from "styled-components";

export const supportDeviceSize = 1080;

export const size = {
  mobile: "1280px",
};

export const device = {
  mobile: `max-width: ${size.mobile}`,
};

export const GlobalStyle = createGlobalStyle`
html {
font-size: 62.5%; // 1rem = 10px 로 변경 한 것
// 참고링크 = https://stackoverflow.com/questions/68790660/setting-root-font-size-not-affecting-rem-units-in-safari-for-margin-padding-et
// 128px => 12.8rem , 4px => 0.4rem 가능!
}

body {
    background: white;
    margin: 0;
    padding: 0;
    font-family: 'NanumSquareNeo-Variable';
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;
    @font-face {
    font-family: 'NanumSquareNeo-Variable';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_11-01@1.0/NanumSquareNeo-Variable.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}
}
`;
